var SingleMap = function SingleMap(markerCoordinates, zoomLevel, drawMarkers, containerId){
    var map = new google.maps.Map(document.getElementById(containerId), {
        center: markerCoordinates,
        zoom: zoomLevel,
        styles: MapStyle(),
        mapTypeId: 'terrain',
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false
    });

    if(drawMarkers){
        new google.maps.Marker({
            position: markerCoordinates,
            map: map,
            icon: {
                url: window.devicePixelRatio > 1 ? '/assets/images/marker_primary.png' : '/assets/images/marker_primary_025.png',
                anchor: new google.maps.Point(15.5, 40),
                scaledSize: new google.maps.Size(54,40)
            }
        });
    }
}
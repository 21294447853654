var PrizesSlider = function PrizesSlider($elements){
    $($elements.slider).slick({
        lazyLoad: 'ondemand',
        slidesToShow: 2,
        prevArrow: $elements.prevArrow,
        nextArrow: $elements.nextArrow,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}
$(function() {
  var IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  );

  var $body = $("body");

  if (
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1
  ) {
    $($body).addClass("safari");
  }

  var LANGUAGE = $($body).data("lang");
  var MALL_ID = $($body).data("mall-id");

  //Language handler
  LanguageHandler(
    {
      languageLinks: $(".language__menu-list a")
    },
    LANGUAGE,
    MALL_ID
  );

  //MENU
  var menuController = new Menu({
    header: $(".header"),
    anchors: $(
      ".header__primary-nav .header__menu__list:not(.language__menu-list) .header__menu__list-item>a[href^='#'], .header__logo a[href^='#']"
    ),
    goDownAnchors: $(".go-down-anchor a[href^='#'], .go-to-top"),
    submenuAnchors: $(".header__submenu a[href^='#']"),
    submenuMobileAnchors: $(
      ".header__primary-nav .mobile__submenu-list a[href^='#']"
    ),
    menuToggle: $(".header__menu--toggle"),
    menuOverlay: $(".header__mobile-overlay")
  });

  menuController.init();

  //Hero heading scroll revealer
  if ($($body).hasClass("body--homepage")) {
    HeroRevealer({
      homeSection: $(".home-section"),
      homeSectionHeading: $(".home-section .hero-section__content"),
      homePlaceholderHeading: $(".home-placeholder .hero-section__content")
    });
  }

  //Multi Map
  if ($(".multi-map-section").length > 0) {
    var multiMap = new MultiMap(
      {
        mapSection: $(".multi-map-section"),
        malls: $(".map-list__list a"),
        infoWrapper: $(".mall-info__wrapper"),
        closeButton: $(".mall-info__close"),
        mallArticleWrapper: $(".mall-info__article-wrapper"),
        mallGraphicsWrapper: $(".mall-info__graphics-wrapper"),
        mallInfoLoader: $(".mall-info__loader"),
        mallInfo: {
          heading: $(".mall-info__heading"),
          content: $(".mall-info__content"),
          area: $(".mall-info__area"),
          parking: $(".mall-info__parking"),
          areaLabel: $(".mall-info__area-label"),
          parkingLabel: $(".mall-info__parking-label"),
          clientsLink: $(".mall-info__clients-button"),
          rentiersLink: $(".mall-info__rentiers-button"),
          background: $(".mall-info__background")
        }
      },
      "multi-map"
    );
    multiMap.initMap();

    $(window).scroll(function() {
      var inMultiMapSection = $(
        "#" + menuController.getActiveSectionId() + "-section"
      ).hasClass("multi-map-section");
      if (inMultiMapSection && !multiMap.isListOpen()) {
        multiMap.showMapList();
      }
      if (!inMultiMapSection && multiMap.isListOpen()) {
        multiMap.hideMapList();
        multiMap.hideMallInfo();
      }
    });
  }

  //Scroll reveal animations handlers and config
  var scrollReveal = new ScrollReveal({ reset: true });

  for (var delayValue = 150; delayValue <= 400; delayValue += 50) {
    var $revealElements = $(".reveal-animation-" + delayValue);

    if ($revealElements.length > 0) {
      scrollReveal.reveal(".reveal-animation-" + delayValue, {
        duration: 1200,
        origin: "bottom",
        distance: "70px",
        delay: delayValue,
        opacity: 0,
        scale: 1,
        easing: "cubic-bezier(0.245, 0.410, 0.000, 1)"
      });
    }
  }

  scrollReveal.reveal(
    ".about-section ul li, .about-section ol li",
    {
      duration: 1200,
      origin: "bottom",
      distance: "20px",
      delay: 500,
      opacity: 0,
      scale: 1,
      easing: "cubic-bezier(0.245, 0.410, 0.000, 1)"
    },
    200
  );

  //Scroll parallax elements
  var skrollrInstance = skrollr.init({
    forceHeight: false,
    easing: "cubic"
  });
  skrollrInstance.isMobile() ? skrollrInstance.destroy() : true;

  //Strategy desktop slider
  var $strategySlider = $(".strategy__slider");
  $strategySlider.on("init", function onStrategySliderInit() {
    var $currentSlide = $(".strategy__slider-item.slick-current");
    $currentSlide.addClass("strategy__slider-item--revealed");

    $(".strategy__slider-arrow-left").fadeOut();
    $(".strategy__slider-item__next-anchor").click(function() {
      $strategySlider.slick("slickNext");
    });

    for (var i = 1; i < 4; i++) {
      $("#strategy__slider-anchor-" + i).click(
        getStrategyAnchorClickHandler(i)
      );
    }
    function getStrategyAnchorClickHandler(index) {
      return function() {
        $strategySlider.slick("slickGoTo", index);
      };
    }
  });
  $strategySlider.on("beforeChange", function onStrategySliderBeforeChange(
    event,
    slick,
    currentSlide
  ) {
    var $currentSlide = $(
      ".strategy__slider-item[data-slick-index=" + currentSlide + "]"
    );
    $currentSlide.removeClass("strategy__slider-item--revealed");

    if (currentSlide === 0) {
      $(".strategy__slider-arrow-left").fadeIn();
    }
    if (currentSlide === 3) {
      $(".strategy__slider-arrow-right").fadeIn();
    }
  });
  $strategySlider.on("afterChange", function onStrategySliderAfterChange(
    event,
    slick,
    currentSlide
  ) {
    var $currentSlide = $(
      ".strategy__slider-item[data-slick-index=" + currentSlide + "]"
    );
    $currentSlide.addClass("strategy__slider-item--revealed");

    if (currentSlide === 0) {
      $(".strategy__slider-arrow-left").fadeOut();
    }
    if (currentSlide === 3) {
      $(".strategy__slider-arrow-right").fadeOut();
    }
  });
  $strategySlider.slick({
    loop: false,
    arrows: true,
    prevArrow: $(".strategy__slider-arrow-left"),
    nextArrow: $(".strategy__slider-arrow-right")
  });

  //Strategy revealer (mainly for resetting slider on reveal)
  scrollReveal.reveal(".strategy__slider", {
    duration: 1200,
    distance: 0,
    delay: 0,
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.245, 0.410, 0.000, 1)",
    beforeReveal: function(element) {
      $strategySlider.slick('slickGoTo', 0);
    }
  });
  $(".js-reset-strategy-slider").click(function(){
    $strategySlider.slick('slickCurrentSlide') === 0 ? null : $strategySlider.slick('slickGoTo', 0)
  })

  //Strategy slider primary anchors hover bg change
  var $strategySliderPrimaryAnchors = $(".strategy__slider-anchor--primary");
  $strategySliderPrimaryAnchors.each(function() {
    new Image().src = $(this).data("bg-hover");
  });
  $strategySliderPrimaryAnchors.hover(function(event) {
    var backgroundPropString =
      "url(" +
      (event.type === "mouseenter"
        ? $(this).data("bg-hover")
        : $(this).data("bg-default")) +
      ")";
    $(this).css({ "background-image": backgroundPropString });
  });

  //Strategy mobile accordion scroll-to-section
  $strategyMobileAnchors = $(".strategy__mobile-section .panel-title a");
  $strategyMobileCollapsibles = $(".strategy__mobile-section .panel-collapse");
  $strategyMobileCollapsibles.on("shown.bs.collapse", function() {
    var $panel = $(this).closest(".panel");
    $("html,body").animate(
      {
        scrollTop: $panel.offset().top - 113
      },
      350
    );
  });
  $strategyMobileCollapsibles.on("show.bs.collapse", function() {
    $strategyMobileAnchors.each(function() {
      $(this).css({
        "background-image": "url(" + $(this).data("bg-default") + ")"
      });
    });

    var $panel = $(this).closest(".panel");
    var $currentAnchor = $panel.find(".panel-title a");
    $currentAnchor.css({
      "background-image": "url(" + $currentAnchor.data("bg-hover") + ")"
    });
  });
  $strategyMobileCollapsibles.on("hide.bs.collapse", function() {
    var $panel = $(this).closest(".panel");
    var $currentAnchor = $panel.find(".panel-title a");
    $currentAnchor.css({
      "background-image": "url(" + $currentAnchor.data("bg-default") + ")"
    });
  });

  //History mobile chart reveal
  scrollReveal.reveal(".history__mobile-img-wrapper", {
    duration: 1200,
    origin: "bottom",
    distance: "70px",
    delay: 300,
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.245, 0.410, 0.000, 1)",
    beforeReveal: function($element) {
      $($element).scrollLeft(0);
    }
  });
  //History video reveal
  scrollReveal.reveal(".history-video", {
    duration: 1200,
    origin: "bottom",
    distance: "70px",
    delay: 300,
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.245, 0.410, 0.000, 1)",
    beforeReveal: function($element) {
      $element.currentTime = 0;
      $element.play();
    }
  });
  //History desktop video height
  $historyVideo = $(".history-video");
  $(window).resize(function(){
      $historyVideo.attr("height", window.innerHeight - 360);
  }).trigger("resize");

  //Contact staff slider
  $staffSlider = $(".staff-slider");
  function initStaffSlider() {
    $staffSlider.slick({
      slidesToShow: 3,
      prevArrow: $(".staff-slider__arrow--left"),
      nextArrow: $(".staff-slider__arrow--right"),
      responsive: [
        {
          breakpoint: 768,
          settings: "unslick"
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }
  initStaffSlider();

  $(window).resize(function() {
    if ($staffSlider[0].slick.unslicked && window.outerWidth > 767) {
      initStaffSlider();
    }
  });

  //Check if in contact section to display phone call icon
  $phoneIcon = $(".phone-call__mobile");
  $(window).scroll(function() {
    var inContactSection = $(
      "#" + menuController.getActiveSectionId() + "-section"
    ).hasClass("contact-section");
    var phoneIconVisible = $phoneIcon.hasClass("phone-call__mobile-visible");
    if (inContactSection && !phoneIconVisible) {
      $phoneIcon.addClass("phone-call__mobile-visible");
    }
    if (!inContactSection && phoneIconVisible) {
      $phoneIcon.removeClass("phone-call__mobile-visible");
    }
  });

  //Parallax overlay opacity
  function setParallaxOverlaysOpacity($elements) {
    if (window.outerWidth > 992) {
      var calculatedOpacity = Math.min(
        0.7 - (window.outerWidth - 992) * 0.0012,
        1
      );
      calculatedOpacity < 0 ? (calculatedOpacity = 0) : null;
      $elements.css("opacity", calculatedOpacity);
    } else {
      $elements.css("opacity", 0.7);
    }
  }
  var $parallaxOverlays = $(".background-overlay");
  setParallaxOverlaysOpacity($parallaxOverlays);
  $(window).on("resize", function onWindowResizeParallaxOverlay() {
    setParallaxOverlaysOpacity($parallaxOverlays);
  });

  //Key information round progress bars
  var $charts = [
    $(".key-info__chart-1-1"),
    $(".key-info__chart-1-2"),
    $(".key-info__chart-2-1"),
    $(".key-info__chart-2-2")
  ];

  if ($(".key-info__charts-container").length > 0) {
    var charts = [];
    charts[0] = CircleProgress({
      elementSelector: ".key-info__chart-1-1",
      size: "SMALL",
      fullValue: $charts[0].data("max")
    });

    charts[1] = CircleProgress({
      elementSelector: ".key-info__chart-1-2",
      size: "BIG",
      fullValue: $charts[1].data("max"),
      valueSuffix: " m²"
    });

    charts[2] = CircleProgress({
      elementSelector: ".key-info__chart-2-1",
      size: "SMALL",
      fullValue: $charts[2].data("max")
    });

    charts[3] = CircleProgress({
      elementSelector: ".key-info__chart-2-2",
      size: "BIG",
      fullValue: $charts[3].data("max"),
      valueSuffix: " m²"
    });

    scrollReveal.reveal(".key-info__charts-1, .key-info__charts-2", {
      duration: 1200,
      origin: "bottom",
      distance: "70px",
      delay: 250,
      opacity: 0,
      scale: 1,
      easing: "cubic-bezier(0.245, 0.410, 0.000, 1)",
      beforeReveal: function(element) {
        if ($(element).hasClass("key-info__charts-1")) {
          charts[0].set(0);
          charts[0].animate($charts[0].data("value") / $charts[0].data("max"));

          charts[1].set(0);
          charts[1].animate($charts[1].data("value") / $charts[1].data("max"));
        } else {
          charts[2].set(0);
          charts[2].animate($charts[2].data("value") / $charts[2].data("max"));

          charts[3].set(0);
          charts[3].animate($charts[3].data("value") / $charts[3].data("max"));
        }
      }
    });
  }
  //Key information parallax graphics
  $("#key-info__graphics-1").mouseParallax({ moveFactor: 5, direction: -1 });
  $("#key-info__graphics-2").mouseParallax({ moveFactor: 2, direction: 1 });
  $("#key-info__graphics-3").mouseParallax({ moveFactor: 7, direction: 1 });

  //Single mall key information parallax graphics
  $("#single__mall-graphic-1").mouseParallax({ moveFactor: 2, direction: -1 });
  $("#single__mall-graphic-2").mouseParallax({ moveFactor: 5, direction: 1 });
  $("#single__mall-graphic-3").mouseParallax({ moveFactor: 7, direction: 1 });

  //Key information parallax graphics
  $("#mall-info__graphics-1").mouseParallax({ moveFactor: 3, direction: -1 });
  $("#mall-info__graphics-2").mouseParallax({ moveFactor: 2, direction: 1 });
  $("#mall-info__graphics-3").mouseParallax({ moveFactor: 5, direction: 1 });
  $("#mall-info__graphics-4").mouseParallax({ moveFactor: 5, direction: 1 });

  //Multimedia slider
  var $multimediaSlider = $(".multimedia__slider");

  var $multimediaSliderVideos = $multimediaSlider.find("video");
  $multimediaSliderVideos.each(function() {
    $(this)[0].onerror = function(event) {
      $(event.target).data("is-unplayable", true);
    };
  });

  if ($multimediaSlider.length > 0) {
    $multimediaSlider.slick({
      prevArrow: $(".multimedia-slider__arrow--left"),
      nextArrow: $(".multimedia-slider__arrow--right"),
      dots: true,
      appendDots: $(".multimedia-slider__dots"),
      dotsClass: "slider-dots"
    });
    $multimediaSlider.on("beforeChange", function(
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      var $nextSlide = $multimediaSlider.find(
        "[data-slick-index='" + nextSlide + "']"
      );
      var $futureSlide = $nextSlide.next();
      if ($nextSlide.attr("data-image-src")) {
        $nextSlide.css({
          "background-image": "url(" + $nextSlide.attr("data-image-src") + ")"
        });
      }
      if ($futureSlide.attr("data-image-src")) {
        $futureSlide.css({
          "background-image": "url(" + $futureSlide.attr("data-image-src") + ")"
        });
      }

      var $nextSlideVideo = $nextSlide.find("video");
      if ($nextSlideVideo.length > 0) {
        if (!$nextSlideVideo.data("is-unplayable")) {
          $nextSlideVideo[0].currentTime = 0;
          $nextSlideVideo[0].play();
        }
      }
    });
    $multimediaSlider.slick("slickGoTo", 0, true);
  }

  //Prizes slider
  PrizesSlider({
    slider: $(".prizes__slider"),
    prevArrow: $(".prizes__slider-arrow--left"),
    nextArrow: $(".prizes__slider-arrow--right")
  });

  //Contact form textarea counter
  var textareaCounter = new TextareaCounter(
      $(".message-textarea"),
      $(".textarea-counter"),
      MESSAGE_LENGTH
  );
  textareaCounter.init();

  //Contact form
  var MESSAGE_LENGTH = 220;
  var $contactForm = $("[name='contact-form']");
  var contactForm = new ContactForm(
    $contactForm[0],
    textareaCounter,
    "/contact/" + LANGUAGE,
    "POST",
    [
      {
        name: "subject",
        rules: "required",
        messages: $contactForm.find("[name='subject']").data("error-required")
      },
      {
        name: "name",
        rules: "required",
        messages: $contactForm.find("[name='name']").data("error-required")
      },
      {
        name: "telephone",
        rules: "required",
        messages: $contactForm.find("[name='telephone']").data("error-required")
      },
      {
        name: "captcha",
        rules: "required",
        messages: $contactForm.find("[name='captcha']").data("error-required")
      },
      {
        name: "email",
        rules: {
          required: true,
          regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        messages: {
          required: $contactForm.find("[name='email']").data("error-required"),
          regex: $contactForm.find("[name='email']").data("error-valid")
        }
      },
      {
        name: "message",
        rules: {
          required: true,
          maxlength: MESSAGE_LENGTH
        },
        messages: {
          required: $contactForm.find("[name='message']").data("error-required"),
          maxlength: $contactForm.find("[name='message']").data("error-maxlength")
        }
      },
      {
        name: "checkbox",
        rules: "required",
        messages: $contactForm.find("[name='checkbox']").data("error-required")
      },
    ]
  );

  contactForm.init();


  //Single mall desktop menu select
  var currentBaseHref = $("base").attr("href");
  var $headerSubmenuSelect = $(".header__submenu-select");
  $headerSubmenuSelect.on("changed.bs.select", function() {
    if ($(this).val() !== currentBaseHref) {
      window.location.replace($(this).val());
    }
  });

  $headerSubmenuSelect.val(currentBaseHref);

  //Single mall location map
  var $singleMap = $("#single-mall-map");
  if ($singleMap.length > 0) {
    SingleMap(
      {
        lat: $singleMap.data("lat") || 52.232855,
        lng: $singleMap.data("lng") || 20.9211132
      },
      $singleMap.data("lat") ? 18 : 12,
      $singleMap.data("lat"),
      "single-mall-map"
    );
  }

  //reveal cookie law info
  var $cookieLawInfo = $(".cookie-law-info__wrapper");
  if (localStorage.getItem("dekada-cookie-law")) {
    $cookieLawInfo.addClass("accepted");
  } else {
    $cookieLawInfo.addClass("visible");
    $cookieLawInfo.find(".cookies-button").click(function() {
      localStorage.setItem("dekada-cookie-law", true);
      $cookieLawInfo.removeClass("visible");
    });
  }

  //Scroll to section from URL hash
  window.location.hash
    ? menuController.scrollToSectionByHash(window.location.hash)
    : null;
});

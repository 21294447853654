var Menu = function Menu(DOMElements) {
  var SECTION_SUFFIX = "-section";
  var SINGLE_MENU_HEIGHT = 63;
  var DOUBLE_MENU_HEIGHT = 113;
  var SCROLL_CONTAINER_SELECTOR = ".scroller";
  var $elements = DOMElements;

  var scrollToSection = function scrollToSection(hash, $anchors, $header, $currentAnchor){
    var sectionId = hash + SECTION_SUFFIX;
    $anchors.addClass("automatic-scrolling");
    $header.addClass("automatic-scrolling");
    if(!$currentAnchor){
      var $currentAnchor = $anchors.filter("[href='"+hash+"']");
    }    
    var headerOffset = ($currentAnchor.data("has-submenu") || $currentAnchor.data("is-submenu")) ? DOUBLE_MENU_HEIGHT : SINGLE_MENU_HEIGHT;
    $('html, body').animate({
      scrollTop: $(sectionId).offset().top - headerOffset
    }, 750, function(){      
      $anchors.removeClass("automatic-scrolling");
      $header.removeClass("automatic-scrolling");
    });
  }
  var setActiveSection = function setActiveSection(id){
    window.location.hash = Menu.activeSectionId = id;
  }
  var setSubmenuVisibility = function(hasSubmenu, $header){
    var $collapse = $header.find(".collapse");
    if(hasSubmenu === true){
      $header.addClass("header--submenu");
      $collapse.collapse("show")
    } else if(hasSubmenu === false){
      $header.removeClass("header--submenu");
      $collapse.collapse("hide");
    }
  }
  var scrollToMenuItem = function scrollToMenuItem($item){
    var $scrollContainer = $item.closest(SCROLL_CONTAINER_SELECTOR);
    $scrollContainer.stop(true, true).scrollTo($item, {duration: 100, axis: 'x', offset: -16});
  }
  var attachFixedHeaderEvents = function attachFixedHeaderEvents($header) {
    $(window).on("scroll", function onWindowScroll() {
      if ($(window).scrollTop() > 0) {
        this.isFixed = true;
        window.innerWidth < 768 ? $header.addClass("header--solid") : null;
      } else {
        this.isFixed = false;
        window.innerWidth < 768 ? $header.removeClass("header--solid") : null;
      }
      if(window.innerWidth > 767){
        if($(window).scrollTop() > window.innerHeight){
          $header.addClass("header--solid");
        } else {
          $header.removeClass("header--solid");
        }
      }
    }).trigger("scroll");
  };
  var attachActiveSectionEvents = function attachActiveSectionEvents($anchors, $header, isSubmenu){
    var lastId = null;
    var topMenuHeight = $header.outerHeight()+1;

    var $scrollItems = $anchors.map(function(){
      var $item = $($(this).attr("href") + SECTION_SUFFIX);
       if ($item.length) { return $item; }
    });

    $(window).scroll(function(){
        var fromTop = $(this).scrollTop()+topMenuHeight;
        
        var cur = $scrollItems.map(function(){
          if ($(this).offset().top - 0.25*window.innerHeight < fromTop)
            return this;
        });

        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id.slice(0, -SECTION_SUFFIX.length) : "";
        if (lastId !== id && id.length > 0) {
            lastId = id;

            var $currentAnchor = $anchors.filter("[href='#"+id+"']");

            if(!isSubmenu){
              setSubmenuVisibility($currentAnchor.data("has-submenu"), $header);
            } else {
              scrollToMenuItem($currentAnchor);
            }

            $anchors.removeClass("active");
            $currentAnchor.addClass("active");

            setActiveSection(id);
        }
     });
  }

  var attachAnchorEvents = function attachAnchorEvents($anchors, $header, $menuToggle, inMobileMenu){
    $anchors.each(function(){
      $(this).on("click", function onAnchorClick(){
          if(window.innerWidth < 768 && !$(this).hasClass("header__logo-link") && inMobileMenu && !$(this).data("has-submenu")){
            setTimeout(function(){
              $menuToggle.trigger("click");
            }, 300);
          }

          if(window.innerWidth > 767 || (window.innerWidth < 768 && !$(this).data("has-submenu"))){
            scrollToSection($(this).attr("href"), $anchors, $header);
          }          
      })
    })
  }
  var attachMenuToggleEvents = function attachMenuToggleEvents($menuToggle, $menuOverlay){
    $menuToggle.on("click", function menuToggleClick() {
      $(this).toggleClass("open");

      $menuOverlay.toggleClass("open");
      if(!$menuOverlay.hasClass("open")){
        $menuOverlay.addClass("closed");
      }
      $menuOverlay.on('transitionend webkitTransitionEnd oTransitionEnd', function () {
        $menuOverlay.removeClass("closed");
      });

      $(document.body).toggleClass("no-scroll");
    });
  }

  var attachGoDownAnchorEvents = function($anchors, $header){
    $anchors.each(function(){
      $(this).on("click", function onGoDownAnchorClick(){
        scrollToSection($(this).attr("href"), $anchors, $header);
      })
    })
  }

  this.activeSectionId = null;
  this.isFixed = false;
  this.init = function() {
    attachMenuToggleEvents($elements.menuToggle, $elements.menuOverlay);

    //anchor events for main menu
    attachAnchorEvents($elements.anchors, $elements.header, $elements.menuToggle, true);
    //anchor events for submenu
    attachAnchorEvents($elements.submenuAnchors, $elements.header, $elements.menuToggle, false);
    //anchor events for mobile submenu
    attachAnchorEvents($elements.submenuMobileAnchors, $elements.header, $elements.menuToggle, true);

    attachFixedHeaderEvents($elements.header);
    
    //active sections for main menu
    attachActiveSectionEvents($elements.anchors, $elements.header, false);
    //active sections for submenu
    attachActiveSectionEvents($elements.submenuAnchors, $elements.header, true);
    //active sections for mobile submenu
    attachActiveSectionEvents($elements.submenuMobileAnchors, $elements.header, true);

    attachGoDownAnchorEvents($elements.goDownAnchors, $elements.header);

    $(window).resize(function(){
      if(window.innerWidth < 768){
        SINGLE_MENU_HEIGHT = 50;
        DOUBLE_MENU_HEIGHT = 100;
      }
    }).trigger("resize");
    if($elements.header.hasClass("header--submenu-permanent")){
      $elements.header.addClass("header--submenu");
    }
  };
  this.scrollToSectionByHash = function scrollToSectionByHash(hash){
    var $currentAnchor = $("[href='"+hash+"']");
    if($currentAnchor.data("is-submenu")){
      scrollToSection(hash, $elements.submenuAnchors, $elements.header, $currentAnchor);
    } else {
      scrollToSection(hash, $elements.anchors, $elements.header);
    }
    
  }
  this.getActiveSectionId = function(){
    return Menu.activeSectionId;
  }
};



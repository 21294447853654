var TextareaCounter = function(textareaElement, textareaCounterElement, maxValue){
    var counterValueElement = jQuery(textareaCounterElement).find(".counter-value");
    var counterMaxElement = jQuery(textareaCounterElement).find(".counter-max");

    this.init = function(){
        if(jQuery(textareaCounterElement).length > 0){
            
            jQuery(counterMaxElement).html(maxValue);
            
            jQuery(textareaElement).bind('input propertychange', function(){
                jQuery(counterValueElement).html(jQuery(textareaElement).val().length.toString());
            }).trigger('propertychange');

            return true;

        } else return false;
    }
    this.setValue = function setValue(value){
        jQuery(counterValueElement).html(value);
    }
}
var MultiMap = function MultiMap($elements, mapId){
    var listOpen = false;
    var detailsOpen = false;
    var map = {};
    var currentLanguage = $("body").data("lang");
    var CENTER_COORDINATES = new google.maps.LatLng(52.065466, 19.452831);
    var MARKER_ICONS = {
        open: {
            large: '/assets/images/marker_default.png',
            small: '/assets/images/marker_default_025.png'
        },
        preparation: {
            large: '/assets/images/marker_prep_v2.png',
            small: '/assets/images/marker_prep_025_v2.png'
        },
        active: {
            open: {
                large: '/assets/images/marker_primary.png',
                small: '/assets/images/marker_primary_025.png'
            },
            preparation: {
                large: '/assets/images/marker_prep_v2_hover.png',
                small: '/assets/images/marker_prep_025_v2_hover.png'
            }
        }
    }
    var markers = {};
    
    var RESPONSIVE_OPTIONS = [
        {
            maxWidth: 1440,
            zoom: 6,
            panXinit: 0.15,
            panXresize: 0
        },
        {
            maxWidth: false,
            zoom: 7,
            panXinit: 0.15,
            panXresize: 0
        }
    ];

    var getResponsiveOptions = function getResponsiveOptions(){
        var windowWidth = window.innerWidth;
        var responsiveOptions = {};

        for(i = 0; i < RESPONSIVE_OPTIONS.length; i++){
            if(!RESPONSIVE_OPTIONS[i].maxWidth){
                responsiveOptions = RESPONSIVE_OPTIONS[i];
                break;
            } else {
                if(windowWidth < RESPONSIVE_OPTIONS[i].maxWidth){
                    responsiveOptions = RESPONSIVE_OPTIONS[i];
                    break;
                }
            }
        }
        return responsiveOptions;
    }
    var recenterMap = function recenterMap(isResize){
        var responsiveOptions = getResponsiveOptions();
        map.setZoom(responsiveOptions.zoom);
        map.setCenter(CENTER_COORDINATES);

        var panX = isResize ? responsiveOptions.panXresize : responsiveOptions.panXinit;
        map.panBy(window.innerWidth * panX, 0);
    }
    var getMarkerIcon = function getMarkerIcon(status, state){
        var iconUrl = '';
        if(state){
            iconUrl = window.devicePixelRatio > 1 ? MARKER_ICONS[state][status].large : MARKER_ICONS[state][status].small
        } else {
            iconUrl = window.devicePixelRatio > 1 ? MARKER_ICONS[status].large : MARKER_ICONS[status].small
        }
        var iconObject = {
            url: iconUrl,
            anchor: new google.maps.Point(15.5, 40),
            scaledSize: new google.maps.Size(54,40)
        }
        return iconObject;
    }
    var addMarkers = function addMarkers(){
        $elements.malls.each(function(){
            var $mallLink = $(this);
            var marker = markers[$mallLink.data("id")] = new google.maps.Marker({
                position: {lat: $mallLink.data("lat"), lng: $mallLink.data("lng")},
                map: map,
                status: $mallLink.data("status"),
                icon: getMarkerIcon($mallLink.data("status")),
            });
            marker.addListener('mouseover', function(){
                marker.setIcon(getMarkerIcon($mallLink.data("status"), "active"));
                $mallLink.addClass("marker-hovered");
            });
            marker.addListener('mouseout', function(){
                marker.setIcon(getMarkerIcon(marker.status));
                $mallLink.removeClass("marker-hovered");
            });
            marker.addListener('click', function(){
                toggleMallInfo($mallLink.data("id"));
            })
        });
    }
    var attachMallLinkHandlers = function(){
        $elements.malls.each(function(){
            $(this).on("mouseenter", function(){
                markers[$(this).data("id")].setIcon(getMarkerIcon(markers[$(this).data("id")].status, "active"))
            });
            $(this).on("mouseleave", function() {
                markers[$(this).data("id")].setIcon(getMarkerIcon(markers[$(this).data("id")].status))
            });
            $(this).on("click", function(){
                toggleMallInfo($(this).data("id"));
            })
        })
    }
    var closeMallInfo = function(){
        $elements.infoWrapper.removeClass("mall-info__wrapper--open");
        $elements.malls.removeClass("active");
    }
    var attachMallInfoClose = function(){
        $elements.closeButton.click(function(){
            closeMallInfo();
        })
    }
    var getMallInfo = function(mallId, language){
        return $.ajax({
            url: '/mall/'+mallId+'/'+language+'/homepage',
        });
    }
    var injectMallInfo = function(mallInfo){
        $elements.mallInfo.heading.html(mallInfo.name);
        $elements.mallInfo.content.html(mallInfo.content);
        mallInfo.data.area ? $elements.mallInfo.area.html(mallInfo.data.area).show() : $elements.mallInfo.areaLabel.hide();
        mallInfo.data.parking ? $elements.mallInfo.parking.html(mallInfo.data.parking).show() : $elements.mallInfo.parkingLabel.hide();
        mallInfo.clients_url ? $elements.mallInfo.clientsLink.attr("href", mallInfo.clients_url).show() : $elements.mallInfo.clientsLink.hide();
        mallInfo.rentiers_url ? $elements.mallInfo.rentiersLink.attr("href", mallInfo.rentiers_url).show() : $elements.mallInfo.rentiersLink.hide();
    }
    var toggleMallInfo = function toggleMallInfo(mallId){
        $elements.malls.removeClass("active");
        $($elements.malls).filter("[data-id="+mallId+"]").addClass("active");

        $elements.infoWrapper.addClass("mall-info__wrapper--open");

        $elements.mallArticleWrapper.addClass("hidden-anim");
        $elements.mallGraphicsWrapper.addClass("hidden-anim");
        $elements.mallInfoLoader.removeClass("hidden-anim");
        getMallInfo(mallId, currentLanguage).done(function(mallInfo){
            injectMallInfo(mallInfo);

            $.ajax({
                url: mallInfo.background
            }).done(function(response){
                $elements.mallInfo.background.css({"background-image": "url('"+mallInfo.background+"')"});
                $elements.mallInfoLoader.addClass("hidden-anim");
                $elements.mallGraphicsWrapper.removeClass("hidden-anim");
                $elements.mallArticleWrapper.removeClass("hidden-anim");
            })
        })
    }
    this.initMap = function initMap(){
        var responsiveOptions = getResponsiveOptions();

        map = new google.maps.Map(document.getElementById(mapId), {
            center: CENTER_COORDINATES,
            zoom: responsiveOptions.zoom,
            styles: MapStyle(),
            mapTypeId: 'terrain',
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false
        });
        map.panBy(window.innerWidth * responsiveOptions.panXinit, 0);

        var windowWidth = $(window).width();
        $(window).on('resize orientationchange', function(){
            if ($(window).width() !== windowWidth) {
                windowWidth = $(window).width();
                recenterMap(true);
            }
        });

        addMarkers();
        attachMallLinkHandlers();
        attachMallInfoClose();
    };

    this.showMapList = function(){
        $elements.mapSection.addClass("multi-map-section--list-open");
        this.listOpen = true;
    }
    this.hideMapList = function(){
        $elements.mapSection.removeClass("multi-map-section--list-open");
        this.listOpen = false;
    }
    this.hideMallInfo = function(){
        closeMallInfo();
    }
    this.isListOpen = function isListOpen(){
        return this.listOpen;
    }
}
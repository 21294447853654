var CircleProgress = function CircleProgress(options) {
  
  var COLORS = {
    PINK: "#e60058",
    TEAL_PRIMARY: "#b7dce2",
    TEAL_LIGHT: "#dbedf0"
  };

  var STROKE_SIZES = {
      BIG: 3,
      SMALL: 4
  }

  var DEFAULT_DURATION = 1500;


  return new ProgressBar.Circle(options.elementSelector, {
    color: COLORS.PINK,
    strokeWidth: STROKE_SIZES[options.size],
    trailWidth: STROKE_SIZES[options.size],
    trailColor: COLORS.TEAL_LIGHT,
    easing: "easeInOut",
    duration: options.duration || DEFAULT_DURATION,
    text: {
      autoStyleContainer: false,
      style: {
        color: "#666",
        fontSize: "26px",
        fontWeight: "600",
        position: "absolute",
        left: "50%",
        top: "50%",
        padding: 0,
        margin: 0,
        whiteSpace: 'nowrap',
        transform: {
          prefix: true,
          value: "translate(-50%, -50%)"
        }
      }
    },
    from: { color: "#aaa" },
    to: { color: "#333" },
    step: function(state, circle) {
      var value = numeral(Math.round(circle.value() * options.fullValue)).format("0,0").replace(/,/g, " ");
      value = options.valueSuffix ? value + options.valueSuffix : value;
      if (value === 0) {
        circle.setText("0");
      } else {
        circle.setText(value);
      }
    }
  });
};

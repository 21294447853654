var LanguageHandler =  function LanguageHandler($elements, currentLanguage, mallId){

    $.get("/map/"+currentLanguage+(mallId ? '/' + mallId : ''), function(response){
        var menuMap = response;

        window.onhashchange = function(){
            $elements.languageLinks.each(function(){
                var destinationUrl = $(this).data("url");
                var destinationLanguage = $(this).data("lang");
                var currentSection = window.location.hash.slice(1);
                var destinationSection = menuMap[currentSection] ? menuMap[window.location.hash.slice(1)][destinationLanguage].url : '';

                $(this).attr("href", destinationUrl + '#' + destinationSection);
            })
        }
        window.onhashchange();
    });
}
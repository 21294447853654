var HeroRevealer = function HeroRevealer($elements){

    function revealHandler($destinationElement, $movingElement, distanceFactor){
        var fullDistance = $destinationElement.offset().top - $(window).scrollTop();
        var zeroDistance = fullDistance + $destinationElement.height()*distanceFactor;
        var elementPosition = $movingElement.offset().top - $(window).scrollTop();
        var fromFullDistance = elementPosition - fullDistance;
        
        var destinationOpacityLevel = 0;
        var movingOpacityLevel = 0;
        if(fromFullDistance > 0){
            destinationOpacityLevel = 0;
            if(elementPosition < zeroDistance && $(window).scrollTop() !== 0){
                movingOpacityLevel = Math.max(0, 1 - (fromFullDistance/(zeroDistance - fullDistance)));
            } else {
                movingOpacityLevel = 0;
            }
        } else {
            destinationOpacityLevel = 1;
            movingOpacityLevel = 0;
        }
        $movingElement.css({"opacity": movingOpacityLevel});
        $destinationElement.css({"opacity": destinationOpacityLevel});
    }

    $(window).on('scroll mousewheel', function(){
        
        if($(window).scrollTop() > window.innerHeight * 0.75){
            $elements.homeSection.addClass("home-section--absolute");
        } else {
            $elements.homeSection.removeClass("home-section--absolute");
        }

        if(window.innerWidth > 767){
            revealHandler($elements.homeSectionHeading, $elements.homePlaceholderHeading, 1.5);
        }
    })
}
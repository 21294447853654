var MapStyle = function MapStyle() {
  return [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [{ color: "#a7b4bc" }]
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [{ color: "#193235" }]
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#314246" }]
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [{ color: "#cee0e3" }]
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [{ color: "#d5e2ea" }]
    },
    {
      featureType: "landscape.natural",
      elementType: "all",
      stylers: [{ color: "#deebf3" }]
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [{ color: "#e4f6f9" }]
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry.fill",
      stylers: [{ color: "#cee0e3" }]
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [{ color: "#d9e6ee" }]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#909da5" }]
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ saturation: "-100" }]
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#909da5" }]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ saturation: "-100" }, { lightness: "31" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [{ color: "#e4f1f9" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#c9d6de" }]
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [{ visibility: "on" }]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#5b6870" }]
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.fill",
      stylers: [{ color: "#cfdce4" }]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }]
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [{ color: "#ffffff" }]
    },
    {
      featureType: "transit",
      elementType: "labels.text",
      stylers: [{ saturation: "-100" }]
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ saturation: "-100" }]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#b9cbce" }]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#76838b" }]
    }
  ];
};

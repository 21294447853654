var ContactForm = function ContactForm($form, textareaCounter, action, method, fields){
    
    var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var $submitButton = {};
    var validatorObject = {};
    var $formSelect = $($form).find("select");
    var isFormSent = false;

    var submitForm = function submitForm(){

        laddaSubmitButton.start();
        
        $submitButton.removeClass("contact__submit-button--error");

        var formData = new FormData($form);
        var request = new XMLHttpRequest();
        
        request.onreadystatechange = function(){
            var response = {};

            if(request.readyState == XMLHttpRequest.DONE) {
                if(request.status === 200){
                    $submitButton.find(".ladda-label").html($submitButton.data("label-success"));
                    $submitButton.addClass("contact__submit-button--success");
                    $("#checkrodo").addClass("rodo-valid");
                    $(".rodo_consent--error").css('display', 'none');
                    $form.reset();

                    $formSelect.val('default');
                    $formSelect.selectpicker("refresh");
                    textareaCounter.setValue(0);
                } else {
                    $submitButton.find(".ladda-label").html($submitButton.data("label-error"));
                    $submitButton.addClass("contact__submit-button--error");

                }
                laddaSubmitButton.stop();
                isFormSent = true;
            }
        }

        request.open(method, action);
        request.send(formData);
    }
    var laddaSubmitButton = {};

    var createValidationObject = function createValidationObject($form, fields, submitHandler){
        var fieldRules = {};
        var fieldMessages = {};

        fields.forEach(function(field){
            fieldRules[field.name] = field.rules;
            fieldMessages[field.name] = field.messages;
        });
//rodobox
        return $($form).validate({
            invalidHandler: function(event, validator) {
                if ($("#checkrodo-input").is(':checked')) {
                    $("#checkrodo").addClass("rodo-valid");
                    $(".rodo_consent--error").css('display', 'none');
                } else {
                    $("#checkrodo").addClass("rodo-error");
                    $(".rodo_consent--error").css('display', 'block');
                }
            },
            submitHandler: submitHandler,
            rules: fieldRules,
            messages: fieldMessages
        });
    }

    this.init = function(){
        $.validator.addMethod(
            "regex",
            function (value, element, regexp) {
                var re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            },
            "Please check your input."
        );

        validatorObject = createValidationObject($form, fields, submitForm);
        $submitButton = $(".contact__submit-button");

        laddaSubmitButton = Ladda.create(
            document.querySelector(".contact__submit-button")
        );

        $formSelect.on("changed.bs.select",  function(){
            validatorObject.element("select");
        })

        $('#reloadCaptcha').on('click', function (){
            $.ajax({
                url: '/captcha/api/math',
                success: function(result){
                    $('#captchaImage').attr('src', result.img);
                    $('#captchaKey').attr('value', result.key);
                }
            });
        });

        $($form).find(":input").change(function(){
            if(isFormSent){
                isFormSent = false;
                $("#checkrodo").removeClass("rodo-error");

                $(".rodo_consent--error").css('display', 'none');
                $submitButton.removeClass("contact__submit-button--success");
                $submitButton.removeClass("contact__submit-button--error");
                $submitButton.find(".ladda-label").html($submitButton.data("label-send"));
            }
        })
    }
}